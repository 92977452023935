// extracted by mini-css-extract-plugin
export var achievementsSlider__badge_1 = "fy_Mf";
export var achievementsSlider__badge_10 = "fy_Mq";
export var achievementsSlider__badge_11 = "fy_Mr";
export var achievementsSlider__badge_12 = "fy_Ms";
export var achievementsSlider__badge_13 = "fy_Mt";
export var achievementsSlider__badge_14 = "fy_Mv";
export var achievementsSlider__badge_15 = "fy_Mw";
export var achievementsSlider__badge_16 = "fy_Mx";
export var achievementsSlider__badge_17 = "fy_My";
export var achievementsSlider__badge_18 = "fy_Mz";
export var achievementsSlider__badge_19 = "fy_MB";
export var achievementsSlider__badge_2 = "fy_Mg";
export var achievementsSlider__badge_3 = "fy_Mh";
export var achievementsSlider__badge_4 = "fy_Mj";
export var achievementsSlider__badge_5 = "fy_Mk";
export var achievementsSlider__badge_6 = "fy_Ml";
export var achievementsSlider__badge_7 = "fy_Mm";
export var achievementsSlider__badge_8 = "fy_Mn";
export var achievementsSlider__badge_9 = "fy_Mp";
export var achievementsSlider__logo = "fy_Md";
export var achievementsSlider__swiperSliderWrapper = "fy_Mc";