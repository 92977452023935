// extracted by mini-css-extract-plugin
export var companyInfo__card = "cp_p7";
export var companyInfo__cardText = "cp_p9";
export var companyInfo__cardTitle = "cp_p8";
export var companyInfo__description = "cp_p6";
export var companyInfo__flex = "cp_qg";
export var companyInfo__head = "cp_qf";
export var companyInfo__headSm = "cp_qd";
export var companyInfo__title = "cp_p5";
export var companyInfo__topWrapper = "cp_qc";
export var companyInfo__wrapper = "cp_qb";