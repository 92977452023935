// extracted by mini-css-extract-plugin
export var succesStoriesCard = "fF_Nf";
export var succesStoriesCardRow = "fF_Nq";
export var succesStoriesCard__bottomWrapper = "fF_Nl";
export var succesStoriesCard__category = "fF_Nn";
export var succesStoriesCard__categoryWrapper = "fF_Nm";
export var succesStoriesCard__description = "fF_Nk";
export var succesStoriesCard__image = "fF_Nh";
export var succesStoriesCard__locaation = "fF_Np";
export var succesStoriesCard__logosWrapper = "fF_Nj";
export var succesStoriesCard__title = "fF_Ng";