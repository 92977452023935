// extracted by mini-css-extract-plugin
export var homeWebDevProcess = "cq_qh";
export var homeWebDevProcess__between = "cq_qj";
export var homeWebDevProcess__contentFive = "cq_qs";
export var homeWebDevProcess__contentFour = "cq_qr";
export var homeWebDevProcess__contentOne = "cq_qn";
export var homeWebDevProcess__contentThree = "cq_qq";
export var homeWebDevProcess__contentTwo = "cq_qp";
export var homeWebDevProcess__description = "cq_qm";
export var homeWebDevProcess__image = "cq_qk";
export var homeWebDevProcess__title = "cq_ql";